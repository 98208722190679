define("ember-changeset/-private/change", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Change {
    constructor(value) {
      this.value = value;
    }

  }

  _exports.default = Change;
});