define("ember-svg-jar/inlined/quote-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M6 2.5V8q0 .813-.316 1.551t-.855 1.277-1.277.855-1.551.316h-.5q-.203 0-.352-.148t-.148-.352v-1q0-.203.148-.352t.352-.148h.5q.828 0 1.414-.586t.586-1.414v-.25q0-.312-.219-.531t-.531-.219h-1.75q-.625 0-1.062-.438T.001 5.499v-3q0-.625.438-1.062T1.501.999h3q.625 0 1.062.438t.438 1.062zm7 0V8q0 .813-.316 1.551t-.855 1.277-1.277.855-1.551.316h-.5q-.203 0-.352-.148t-.148-.352v-1q0-.203.148-.352t.352-.148h.5q.828 0 1.414-.586t.586-1.414v-.25q0-.312-.219-.531t-.531-.219h-1.75q-.625 0-1.062-.438t-.438-1.062v-3q0-.625.438-1.062T8.501.999h3q.625 0 1.062.438t.438 1.062z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "13",
      "height": "14",
      "viewBox": "0 0 13 14"
    }
  };
  _exports.default = _default;
});