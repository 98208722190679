define("ember-changeset/utils/merge-nested", ["exports", "ember-changeset/utils/set-nested-property"], function (_exports, _setNestedProperty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = mergeNested;
  const {
    keys
  } = Object;
  /**
   * Given an array of objects, merge their keys into a new object and
   * return the new object.
   *
   * This function merges using `setNestedProperty`.
   */

  function mergeNested() {
    let finalObj = {};

    for (var _len = arguments.length, objects = new Array(_len), _key = 0; _key < _len; _key++) {
      objects[_key] = arguments[_key];
    }

    objects.forEach(obj => keys(obj).forEach(key => (0, _setNestedProperty.default)(finalObj, key, obj[key])));
    return finalObj;
  }
});