define("ivy-tabs/components/ivy-tabs-tablist", ["exports", "@ember/component", "ivy-tabs/templates/components/ivy-tabs-tablist", "@ember/array", "@ember/object", "@ember/object/computed", "@ember/utils", "@ember/runloop"], function (_exports, _component, _ivyTabsTablist, _array, _object, _computed, _utils, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.UP_ARROW = _exports.RIGHT_ARROW = _exports.LEFT_ARROW = _exports.DOWN_ARROW = void 0;
  const DOWN_ARROW = 40;
  _exports.DOWN_ARROW = DOWN_ARROW;
  const LEFT_ARROW = 37;
  _exports.LEFT_ARROW = LEFT_ARROW;
  const RIGHT_ARROW = 39;
  _exports.RIGHT_ARROW = RIGHT_ARROW;
  const UP_ARROW = 38;
  /**
   * @module ivy-tabs
   */

  /**
   * @class IvyTabListComponent
   * @namespace IvyTabs
   * @extends Ember.Component
   */

  _exports.UP_ARROW = UP_ARROW;

  var _default = _component.default.extend({
    _registerWithTabsContainer() {
      this.get('tabsContainer').registerTabList(this);
      (0, _runloop.once)(this, this.selectTab);
    },

    _unregisterWithTabsContainer() {
      this.get('tabsContainer').unregisterTabList(this);
    },

    /**
     * The label of the tablist for screenreaders to use.
     *
     * @property aria-label
     * @type String
     * @default ''
     */
    'aria-label': '',

    /**
     * Tells screenreaders to notify the user during DOM modifications.
     *
     * @property aria-live
     * @type String
     * @default 'polite'
     */
    'aria-live': 'polite',

    /**
     * Tells screenreaders that only one tab can be selected at a time.
     *
     * @property aria-multiselectable
     * @type String
     * @default 'false'
     */
    'aria-multiselectable': (0, _object.computed)('isEmpty', function () {
      if (!this.get('isEmpty')) {
        return 'false';
      }
    }).readOnly(),

    /**
     * Tells screenreaders which DOM modification activites to monitor for user
     * notification.
     *
     * @property aria-relevant
     * @type String
     * @default 'all'
     */
    'aria-relevant': 'all',

    /**
     * The `role` attribute of the tab list element.
     *
     * See http://www.w3.org/TR/wai-aria/roles#tablist
     *
     * @property ariaRole
     * @type String
     * @default 'tablist'
     */
    ariaRole: (0, _object.computed)('isEmpty', function () {
      if (!this.get('isEmpty')) {
        return 'tablist';
      } else {
        // FIXME: Ember 3.1.0-beta.1 introduced a bug which does not bind/watch
        // ariaRole changes if it's initially falsy. This sets a non-falsy,
        // "safe" value for ariaRole until it can be a "tablist" when Tabs are
        // added.
        return 'presentation';
      }
    }).readOnly(),
    attributeBindings: ['aria-label', 'aria-live', 'aria-multiselectable', 'aria-relevant'],
    classNames: ['ivy-tabs-tablist'],

    /**
     * Gives focus to the selected tab.
     *
     * @method focusSelectedTab
     */
    focusSelectedTab() {
      this.get('selectedTab').element.focus();
    },

    init() {
      this._super(...arguments);

      (0, _runloop.once)(this, this._registerWithTabsContainer);
    },

    isEmpty: (0, _computed.empty)('tabs'),

    /**
     * Event handler for navigating tabs via arrow keys. The left (or up) arrow
     * selects the previous tab, while the right (or down) arrow selects the next
     * tab.
     *
     * @method keyDown
     * @param {Event} event
     */
    keyDown(event) {
      switch (event.keyCode) {
        case LEFT_ARROW:
        case UP_ARROW:
          this.selectPreviousTab();
          break;

        case RIGHT_ARROW:
        case DOWN_ARROW:
          this.selectNextTab();
          break;

        default:
          return;
      }

      event.preventDefault();
      (0, _runloop.scheduleOnce)('afterRender', this, this.focusSelectedTab);
    },

    layout: _ivyTabsTablist.default,

    /**
     * Adds a tab to the `tabs` array.
     *
     * @method registerTab
     * @param {IvyTabs.IvyTabComponent} tab
     */
    registerTab(tab) {
      this.get('tabs').pushObject(tab);
      (0, _runloop.once)(this, this.selectTab);
    },

    /**
     * Selects the next tab in the list, if any.
     *
     * @method selectNextTab
     */
    selectNextTab() {
      const selectedTab = this.get('selectedTab');
      const tabs = this.get('tabs');
      const length = tabs.get('length');
      let idx = selectedTab.get('index');
      let tab;

      do {
        idx++; // Next from the last tab should select the first tab.

        if (idx === length) {
          idx = 0;
        }

        tab = tabs.objectAt(idx);
      } while (tab && tab.isDestroying && tab !== selectedTab);

      if (tab) {
        tab.select();
      }
    },

    /**
     * Selects the previous tab in the list, if any.
     *
     * @method selectPreviousTab
     */
    selectPreviousTab() {
      const selectedTab = this.get('selectedTab');
      const tabs = this.get('tabs');
      const length = tabs.get('length');
      let idx = selectedTab.get('index');
      let tab;

      do {
        idx--; // Previous from the first tab should select the last tab.

        if (idx < 0) {
          idx = length - 1;
        } // This would only happen if there are no tabs, so stay at 0.


        if (idx < 0) {
          idx = 0;
        }

        tab = tabs.objectAt(idx);
      } while (tab && tab.isDestroying && tab !== selectedTab);

      if (tab) {
        tab.select();
      }
    },

    selectTab() {
      const selection = this.get('selection');

      if ((0, _utils.isNone)(selection) || this.get('tabs.length') === 1) {
        this.selectTabByIndex(0);
      } else {
        this.selectTabByModel(selection);
      }
    },

    /**
     * Select the tab at `index`.
     *
     * @method selectTabByIndex
     * @param {Number} index
     */
    selectTabByIndex(index) {
      const tab = this.get('tabs').objectAt(index);

      if (tab) {
        tab.select();
      }
    },

    selectTabByModel(model) {
      const tab = this.get('tabs').findBy('model', model);

      if (tab) {
        tab.select();
      }
    },

    /**
     * The currently-selected `ivy-tabs-tab` instance.
     *
     * @property selectedTab
     * @type IvyTabs.IvyTabComponent
     */
    selectedTab: (0, _object.computed)('selection', 'tabs.@each.model', function () {
      return this.get('tabs').findBy('model', this.get('selection'));
    }),
    tabs: (0, _object.computed)(function () {
      return (0, _array.A)();
    }).readOnly(),

    /**
     * The `ivy-tabs` component.
     *
     * @property tabsContainer
     * @type IvyTabs.IvyTabsComponent
     * @default null
     */
    tabsContainer: null,

    /**
     * Removes a tab from the `tabs` array.
     *
     * @method unregisterTab
     * @param {IvyTabs.IvyTabComponent} tab
     */
    unregisterTab(tab) {
      const index = tab.get('index');

      if (tab.get('isSelected')) {
        if (index === 0) {
          this.selectNextTab();
        } else {
          this.selectPreviousTab();
        }
      }

      this.get('tabs').removeObject(tab);
    },

    willDestroy() {
      this._super(...arguments);

      (0, _runloop.once)(this, this._unregisterWithTabsContainer);
    }

  });

  _exports.default = _default;
});