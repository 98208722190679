define("ember-validators/ds-error", ["exports", "@ember/object", "@ember/utils", "ember-require-module", "ember-validators/utils/validation-error"], function (_exports, _object, _utils, _emberRequireModule, _validationError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateDsError;
  _exports.getPathAndKey = getPathAndKey;
  const DS = (0, _emberRequireModule.default)('ember-data');
  /**
   *  @class DS Error
   *  @module Validators
   */

  /**
   * @method validate
   * @param {Any} value
   * @param {Object} options
   * @param {Object} model
   * @param {String} attribute
   */

  function validateDsError(value, options, model, attribute) {
    if (!DS) {
      throw new Error('Ember-Data is required to use the DS Error validator.');
    }

    let {
      path,
      key
    } = getPathAndKey(attribute);
    let errors = (0, _object.get)(model, path);

    if (!(0, _utils.isNone)(errors) && errors instanceof DS.Errors && errors.has(key)) {
      return (0, _validationError.default)('ds', null, options, (0, _object.get)(errors.errorsFor(key), 'lastObject.message'));
    }

    return true;
  }

  function getPathAndKey(attribute) {
    let path = attribute.split('.');
    let key = path.pop();
    path.push('errors');
    return {
      path: path.join('.'),
      key
    };
  }
});