define("ember-svg-jar/inlined/minus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11 5.75v1.5q0 .312-.219.531T10.25 8H.75q-.312 0-.531-.219T0 7.25v-1.5q0-.312.219-.531T.75 5h9.5q.312 0 .531.219T11 5.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "11",
      "height": "14",
      "viewBox": "0 0 11 14"
    }
  };
  _exports.default = _default;
});