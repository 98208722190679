define("ember-validators/confirmation", ["exports", "@ember/object", "@ember/debug", "@ember/utils", "ember-validators/utils/validation-error"], function (_exports, _object, _debug, _utils, _validationError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateConfirmation;

  /**
   *  @class Confirmation
   *  @module Validators
   */

  /**
   * @method validate
   * @param {Any} value
   * @param {Object} options
   * @param {String} options.on The attribute to confirm against
   * @param {String} options.allowBlank If true, skips validation if the value is empty
   * @param {Object} model
   * @param {String} attribute
   */
  function validateConfirmation(value, options, model, attribute) {
    let on = (0, _object.get)(options, 'on');
    let allowBlank = (0, _object.get)(options, 'allowBlank');
    (true && !((0, _utils.isPresent)(on)) && (0, _debug.assert)(`[validator:confirmation] [${attribute}] option 'on' is required`, (0, _utils.isPresent)(on)));

    if (allowBlank && (0, _utils.isEmpty)(value)) {
      return true;
    }

    if (!(0, _utils.isEqual)(value, (0, _object.get)(model, on))) {
      return (0, _validationError.default)('confirmation', value, options);
    }

    return true;
  }
});