define("ember-changeset/utils/computed/transform", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transform;
  const {
    keys
  } = Object;
  /**
   * Transform an Object's values with a `transform` function.
   */

  function transform(dependentKey, transform) {
    return (0, _object.computed)(dependentKey, function () {
      let obj = (0, _object.get)(this, dependentKey);
      return keys(obj).reduce((newObj, key) => {
        newObj[key] = transform(obj[key]);
        return newObj;
      }, Object.create(null));
    });
  }
});