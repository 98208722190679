define("ember-font-awesome/templates/components/fa-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1aru2DAC",
    "block": "[[[18,1,[[28,[37,1],null,[[\"fa-icon\"],[[50,\"fa-icon\",0,null,[[\"listItem\"],[true]]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-font-awesome/templates/components/fa-list.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});