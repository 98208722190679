define("ember-changeset/utils/object-without", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = objectWithout;
  const {
    keys
  } = Object;
  /**
   * Merges all sources together, excluding keys in excludedKeys.
   *
   * @param  {string[]}    excludedKeys
   * @param  {...object}        sources
   *
   * @return {object}
   */

  function objectWithout(excludedKeys) {
    for (var _len = arguments.length, sources = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      sources[_key - 1] = arguments[_key];
    }

    return sources.reduce((acc, source) => {
      keys(source).filter(key => excludedKeys.indexOf(key) === -1 || !source.hasOwnProperty(key)).forEach(key => acc[key] = source[key]);
      return acc;
    }, {});
  }
});