define("ember-font-awesome/components/fa-stack", ["exports", "ember", "ember-font-awesome/utils/try-match", "ember-font-awesome/templates/components/fa-stack"], function (_exports, _ember, _tryMatch, _faStack) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    get
  } = _ember.default;

  var _default = _ember.default.Component.extend({
    layout: _faStack.default,
    tagName: 'span',
    classNames: 'fa-stack',
    classNameBindings: ['sizeCssClass'],
    sizeCssClass: computed('size', function () {
      let size = get(this, 'size');

      if (!size) {
        return;
      }

      if ((0, _tryMatch.default)(size, /^fa-/)) {
        return size;
      } else if ((0, _tryMatch.default)(size, /(?:lg|x)$/)) {
        return `fa-${size}`;
      } else {
        return `fa-${size}x`;
      }
    })
  });

  _exports.default = _default;
});