define("ember-svg-jar/inlined/bold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M4.336 11.883q.578.25 1.094.25 2.937 0 2.937-2.617 0-.891-.32-1.406-.211-.344-.48-.578t-.527-.363-.629-.195-.656-.082-.738-.016q-.57 0-.789.078 0 .414-.004 1.242T4.22 9.43l-.008.527q-.008.465-.004.754t.035.652.094.52zm-.109-5.828q.328.055.852.055.641 0 1.117-.102t.859-.348.582-.699.199-1.109q0-.547-.227-.957t-.617-.641-.844-.34-.969-.109q-.391 0-1.016.102 0 .391.031 1.18t.031 1.187q0 .211-.004.625t-.004.617q0 .359.008.539zM0 13l.016-.734q.117-.031.664-.125t.828-.211q.055-.094.098-.211t.066-.262.043-.254.023-.293.004-.266v-.512q0-7.672-.172-8.008-.031-.062-.172-.113t-.348-.086-.387-.055-.379-.035-.238-.023l-.031-.648q.766-.016 2.656-.09T5.585 1q.18 0 .535.004t.527.004q.547 0 1.066.102t1.004.328.844.555.578.816.219 1.074q0 .406-.129.746t-.305.563-.504.449-.57.352-.656.312q1.203.273 2.004 1.047t.801 1.937q0 .781-.273 1.402t-.73 1.02-1.078.668-1.277.379-1.375.109q-.344 0-1.031-.023t-1.031-.023q-.828 0-2.398.086t-1.805.094z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "11",
      "height": "14",
      "viewBox": "0 0 11 14"
    }
  };
  _exports.default = _default;
});