define("ember-metrics/metrics-adapters/intercom", ["exports", "@ember/debug", "ember-metrics/-private/utils/object-transforms", "ember-metrics/-private/utils/remove-from-dom", "ember-metrics/metrics-adapters/base"], function (_exports, _debug, _objectTransforms, _removeFromDom, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Intercom extends _base.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "booted", false);
    }

    toStringExtension() {
      return 'Intercom';
    } // eslint-disable-next-line ember/classic-decorator-hooks


    init() {
      const {
        appId
      } = this.config;
      (true && !(appId) && (0, _debug.assert)(`[ember-metrics] You must pass a valid \`appId\` to the ${this.toString()} adapter`, appId));

      this._injectScript(appId);
    }
    /* eslint-disable */
    // prettier-ignore


    _injectScript(appId) {
      (function () {
        var w = window;
        var ic = w.Intercom;

        if (typeof ic === "function") {
          ic('reattach_activator');
          ic('update', {});
        } else {
          var d = document;

          var i = function () {
            i.c(arguments);
          };

          i.q = [];

          i.c = function (args) {
            i.q.push(args);
          };

          w.Intercom = i;

          (function () {
            var s = d.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = `https://widget.intercom.io/widget/${appId}`;
            var x = d.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
          })();
        }
      })();
    }
    /* eslint-enable */


    identify() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const {
        appId
      } = this.config;
      const compactedOptions = (0, _objectTransforms.compact)(options);
      const {
        distinctId
      } = compactedOptions;
      const props = (0, _objectTransforms.without)(compactedOptions, 'distinctId');
      props.app_id = appId;

      if (distinctId) {
        props.user_id = distinctId;
      }

      (true && !(props.email || props.user_id) && (0, _debug.assert)(`[ember-metrics] You must pass \`distinctId\` or \`email\` to \`identify()\` when using the ${this.toString()} adapter`, props.email || props.user_id));
      const method = this.booted ? 'update' : 'boot';
      window.Intercom(method, props);
      this.booted = true;
    }

    trackEvent() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const compactedOptions = (0, _objectTransforms.compact)(options);
      const {
        event = 'unspecified-event'
      } = compactedOptions;
      const props = (0, _objectTransforms.without)(compactedOptions, 'event');
      window.Intercom('trackEvent', event, props);
    }

    trackPage() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const event = {
        event: 'page viewed'
      };
      const mergedOptions = { ...event,
        ...options
      };
      this.trackEvent(mergedOptions);
    }

    willDestroy() {
      (0, _removeFromDom.default)('script[src*="intercom"]');
      delete window.Intercom;
    }

  }

  _exports.default = Intercom;
});