define("ember-cli-head/services/head-data", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class HeadData extends _service.default {}

  _exports.default = HeadData;
});