define("ember-ace/worker-manifest", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "ace/mode/json_worker": "/assets/ace/worker-json.js",
    "ace/mode/yaml_worker": "/assets/ace/worker-yaml.js",
    "ace/mode/xml_worker": "/assets/ace/worker-xml.js",
    "ace/mode/html_worker": "/assets/ace/worker-html.js",
    "ace/mode/handlebars_worker": "/assets/ace/worker-handlebars.js",
    "ace/mode/plain_text_worker": "/assets/ace/worker-plain_text.js"
  };
  _exports.default = _default;
});