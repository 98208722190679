define("ember-svg-jar/inlined/close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10.141 10.328q0 .312-.219.531L8.86 11.921q-.219.219-.531.219t-.531-.219L5.501 9.624l-2.297 2.297q-.219.219-.531.219t-.531-.219L1.08 10.859q-.219-.219-.219-.531t.219-.531L3.377 7.5 1.08 5.203q-.219-.219-.219-.531t.219-.531l1.062-1.062q.219-.219.531-.219t.531.219l2.297 2.297 2.297-2.297q.219-.219.531-.219t.531.219l1.062 1.062q.219.219.219.531t-.219.531L7.625 7.5l2.297 2.297q.219.219.219.531z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "11",
      "height": "14",
      "viewBox": "0 0 11 14"
    }
  };
  _exports.default = _default;
});