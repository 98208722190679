define("ember-svg-jar/inlined/list-ol", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M2.977 12.656q0 .625-.426.984t-1.059.359q-.828 0-1.344-.516l.445-.688q.383.352.828.352.227 0 .395-.113t.168-.332q0-.5-.82-.438l-.203-.438q.062-.078.254-.34t.332-.422.289-.301v-.008q-.125 0-.379.008t-.379.008v.414H.25V9.998h2.602v.688l-.742.898q.398.094.633.383t.234.688zm.015-4.898V9H.164q-.047-.281-.047-.422 0-.398.184-.727t.441-.531.516-.371.441-.34.184-.352q0-.195-.113-.301t-.309-.105q-.359 0-.633.453l-.664-.461q.187-.398.559-.621t.824-.223q.57 0 .961.324t.391.879q0 .391-.266.715t-.586.504-.59.395-.277.41h.992v-.469h.82zM14 10.25v1.5q0 .102-.074.176T13.75 12h-9.5q-.102 0-.176-.074T4 11.75v-1.5q0-.109.07-.18t.18-.07h9.5q.102 0 .176.074t.074.176zM3 3.227V4H.383v-.773h.836q0-.32.004-.953t.004-.945v-.094h-.016q-.062.133-.391.422l-.555-.594L1.327.071h.828v3.156h.844zM14 6.25v1.5q0 .102-.074.176T13.75 8h-9.5q-.102 0-.176-.074T4 7.75v-1.5q0-.109.07-.18T4.25 6h9.5q.102 0 .176.074T14 6.25zm0-4v1.5q0 .102-.074.176T13.75 4h-9.5q-.102 0-.176-.074T4 3.75v-1.5q0-.102.074-.176T4.25 2h9.5q.102 0 .176.074T14 2.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  };
  _exports.default = _default;
});