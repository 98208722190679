define("ember-svg-jar/inlined/italic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M0 12.984l.133-.664q.047-.016.637-.168t.871-.293q.219-.273.32-.789.008-.055.484-2.258t.891-4.246.406-2.316v-.195q-.187-.102-.426-.145t-.543-.062-.453-.043L2.468 1q.258.016.937.051t1.168.055.941.02q.375 0 .77-.02t.945-.055.77-.051q-.039.305-.148.695-.234.078-.793.223t-.848.262q-.062.148-.109.332t-.07.312-.059.355-.051.328q-.211 1.156-.684 3.277t-.605 2.777l-.102.453-.156.703q-.07.32-.125.652t-.047.449l.008.141q.133.031 1.445.242-.023.344-.125.773-.086 0-.254.012t-.254.012q-.227 0-.68-.078t-.672-.078q-1.078-.016-1.609-.016-.398 0-1.117.07t-.945.086z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "8",
      "height": "14",
      "viewBox": "0 0 8 14"
    }
  };
  _exports.default = _default;
});