define("ember-svg-jar/inlined/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.141 13q-.344 0-1.035-.027t-1.043-.027q-.344 0-1.031.027T9.001 13q-.187 0-.289-.16t-.102-.355q0-.242.133-.359t.305-.133.398-.055.352-.117q.258-.164.258-1.094l-.008-3.055q0-.164-.008-.242-.102-.031-.391-.031H4.376q-.297 0-.398.031-.008.078-.008.242l-.008 2.898q0 1.109.289 1.281.125.078.375.102t.445.027.352.117.156.355q0 .203-.098.375t-.285.172q-.367 0-1.09-.027t-1.082-.027q-.336 0-1 .027t-.992.027q-.18 0-.277-.164t-.098-.352q0-.234.121-.352t.281-.137.371-.059.328-.117q.258-.18.258-1.117l-.008-.445V3.904l.004-.203q.004-.18 0-.285T2 3.115t-.027-.328-.051-.285-.086-.246-.125-.141q-.117-.078-.352-.094t-.414-.016-.32-.109-.141-.352q0-.203.094-.375T.859.997q.359 0 1.082.027t1.082.027q.328 0 .988-.027t.988-.027q.195 0 .293.172t.098.375q0 .234-.133.34t-.301.113-.387.031-.336.102q-.273.164-.273 1.25l.008 2.5q0 .164.008.25.102.023.305.023h5.461q.195 0 .297-.023.008-.086.008-.25l.008-2.5q0-1.086-.273-1.25-.141-.086-.457-.098t-.516-.102-.199-.387q0-.203.098-.375t.293-.172q.344 0 1.031.027t1.031.027q.336 0 1.008-.027t1.008-.027q.195 0 .293.172t.098.375q0 .234-.137.344T13.021 2t-.402.023-.344.098q-.273.18-.273 1.258l.008 7.367q0 .93.266 1.094.125.078.359.105t.418.035.324.121.141.348q0 .203-.094.375t-.281.172z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  };
  _exports.default = _default;
});