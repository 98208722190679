define("ember-font-awesome/components/fa-list", ["exports", "ember", "ember-font-awesome/templates/components/fa-list"], function (_exports, _ember, _faList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ember.default.Component.extend({
    layout: _faList.default,
    tagName: 'ul',
    classNames: 'fa-ul'
  });

  _exports.default = _default;
});