define("ember-changeset/utils/computed/is-empty-object", ["exports", "@ember/debug", "@ember/object", "@ember/utils"], function (_exports, _debug, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isEmptyObject;
  const {
    keys
  } = Object;

  function isEmptyObject(dependentKey) {
    (true && !((0, _utils.isPresent)(dependentKey)) && (0, _debug.assert)('`dependentKey` must be defined', (0, _utils.isPresent)(dependentKey)));
    return (0, _object.computed)(dependentKey, function () {
      return keys((0, _object.get)(this, dependentKey)).length === 0;
    }).readOnly();
  }
});