define("ember-svg-jar/inlined/table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M4 10.75v-1.5q0-.109-.07-.18T3.75 9h-2.5q-.109 0-.18.07T1 9.25v1.5q0 .109.07.18t.18.07h2.5q.109 0 .18-.07t.07-.18zm0-3v-1.5q0-.109-.07-.18T3.75 6h-2.5q-.109 0-.18.07T1 6.25v1.5q0 .109.07.18t.18.07h2.5q.109 0 .18-.07T4 7.75zm4 3v-1.5q0-.109-.07-.18T7.75 9h-2.5q-.109 0-.18.07T5 9.25v1.5q0 .109.07.18t.18.07h2.5q.109 0 .18-.07t.07-.18zm-4-6v-1.5q0-.109-.07-.18T3.75 3h-2.5q-.109 0-.18.07T1 3.25v1.5q0 .109.07.18t.18.07h2.5q.109 0 .18-.07T4 4.75zm4 3v-1.5q0-.109-.07-.18T7.75 6h-2.5q-.109 0-.18.07T5 6.25v1.5q0 .109.07.18t.18.07h2.5q.109 0 .18-.07T8 7.75zm4 3v-1.5q0-.109-.07-.18T11.75 9h-2.5q-.109 0-.18.07T9 9.25v1.5q0 .109.07.18t.18.07h2.5q.109 0 .18-.07t.07-.18zm-4-6v-1.5q0-.109-.07-.18T7.75 3h-2.5q-.109 0-.18.07T5 3.25v1.5q0 .109.07.18t.18.07h2.5q.109 0 .18-.07T8 4.75zm4 3v-1.5q0-.109-.07-.18T11.75 6h-2.5q-.109 0-.18.07T9 6.25v1.5q0 .109.07.18t.18.07h2.5q.109 0 .18-.07t.07-.18zm0-3v-1.5q0-.109-.07-.18T11.75 3h-2.5q-.109 0-.18.07T9 3.25v1.5q0 .109.07.18t.18.07h2.5q.109 0 .18-.07t.07-.18zm1-2.5v8.5q0 .516-.367.883T11.75 12H1.25q-.516 0-.883-.367T0 10.75v-8.5q0-.516.367-.883T1.25 1h10.5q.516 0 .883.367T13 2.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "13",
      "height": "14",
      "viewBox": "0 0 13 14"
    }
  };
  _exports.default = _default;
});