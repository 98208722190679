define("ember-changeset/helpers/changeset", ["exports", "@ember/component/helper", "ember-changeset", "ember-changeset/utils/validator-lookup", "ember-changeset/utils/is-changeset", "ember-changeset/utils/is-promise", "ember-changeset/utils/is-object"], function (_exports, _helper, _emberChangeset, _validatorLookup, _isChangeset, _isPromise, _isObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.changeset = changeset;
  _exports.default = void 0;

  function changeset(_ref) {
    let [obj, validations] = _ref;
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    if ((0, _isChangeset.default)(obj)) {
      return obj;
    }

    if ((0, _isObject.default)(validations)) {
      if ((0, _isPromise.default)(obj)) {
        return obj.then(resolved => new _emberChangeset.default(resolved, (0, _validatorLookup.default)(validations), validations, options));
      }

      return new _emberChangeset.default(obj, (0, _validatorLookup.default)(validations), validations, options);
    }

    if ((0, _isPromise.default)(obj)) {
      return Promise.resolve(obj).then(resolved => new _emberChangeset.default(resolved, validations, {}, options));
    }

    return new _emberChangeset.default(obj, validations, {}, options);
  }

  var _default = (0, _helper.helper)(changeset);

  _exports.default = _default;
});