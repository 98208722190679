define("ivy-tabs/components/ivy-tabs", ["exports", "@ember/component", "@ember/object", "@ember/array", "ivy-tabs/templates/components/ivy-tabs"], function (_exports, _component, _object, _array, _ivyTabs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module ivy-tabs
   */

  /**
   * @class IvyTabsComponent
   * @namespace IvyTabs
   * @extends Ember.Component
   */
  var _default = _component.default.extend({
    classNames: ['ivy-tabs'],
    layout: _ivyTabs.default,

    /**
     * Registers the `ivy-tabs-tablist` instance.
     *
     * @method registerTabList
     * @param {IvyTabs.IvyTabListComponent} tabList
     */
    registerTabList(tabList) {
      this.set('tabList', tabList);
    },

    /**
     * Adds a panel to the `tabPanels` array.
     *
     * @method registerTabPanel
     * @param {IvyTabs.IvyTabPanelComponent} tabPanel
     */
    registerTabPanel(tabPanel) {
      this.get('tabPanels').pushObject(tabPanel);
    },

    /**
     * Set this to the model of the tab you'd like to be selected. Usually it is
     * bound to a controller property that is used as a query parameter, but can
     * be bound to anything.
     *
     * @property selection
     * @type Object
     */
    selection: null,
    tabPanels: (0, _object.computed)(function () {
      return (0, _array.A)();
    }).readOnly(),

    /**
     * Removes the `ivy-tabs-tablist` component.
     *
     * @method unregisterTabList
     * @param {IvyTabs.IvyTabListComponent} tabList
     */
    unregisterTabList() {
      this.set('tabList', null);
    },

    /**
     * Removes a panel from the `tabPanels` array.
     *
     * @method unregisterTabPanel
     * @param {IvyTabs.IvyTabPanelComponent} tabPanel
     */
    unregisterTabPanel(tabPanel) {
      this.get('tabPanels').removeObject(tabPanel);
    }

  });

  _exports.default = _default;
});