define("ivy-tabs/components/ivy-tabs-tab", ["exports", "@ember/component", "@ember/object/computed", "@ember/object", "@ember/runloop"], function (_exports, _component, _computed, _object, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module ivy-tabs
   */

  /**
   * @class IvyTabComponent
   * @namespace IvyTabs
   * @extends Ember.Component
   */
  var _default = _component.default.extend({
    _registerWithTabList() {
      this.get('tabList').registerTab(this);
    },

    _unregisterWithTabList() {
      this.get('tabList').unregisterTab(this);
    },

    /**
     * Accessed as a className binding to apply the tab's `activeClass` CSS class
     * to the element when the tab's `isSelected` property is true.
     *
     * @property active
     * @type String
     * @readOnly
     */
    active: (0, _object.computed)('isSelected', function () {
      if (this.get('isSelected')) {
        return this.get('activeClass');
      }
    }),

    /**
     * The CSS class to apply to a tab's element when its `isSelected` property
     * is `true`.
     *
     * @property activeClass
     * @type String
     * @default 'active'
     */
    activeClass: 'active',

    /**
     * Tells screenreaders which panel this tab controls.
     *
     * See http://www.w3.org/TR/wai-aria/states_and_properties#aria-controls
     *
     * @property aria-controls
     * @type String
     * @readOnly
     */
    'aria-controls': (0, _computed.readOnly)('tabPanel.elementId'),

    /**
     * Tells screenreaders whether or not this tab's panel is expanded.
     *
     * See http://www.w3.org/TR/wai-aria/states_and_properties#aria-expanded
     *
     * @property aria-expanded
     * @type String
     * @readOnly
     */
    'aria-expanded': (0, _computed.readOnly)('aria-selected'),

    /**
     * Tells screenreaders whether or not this tab is selected.
     *
     * See http://www.w3.org/TR/wai-aria/states_and_properties#aria-selected
     *
     * @property aria-selected
     * @type String
     */
    'aria-selected': (0, _object.computed)('isSelected', function () {
      return this.get('isSelected') + ''; // coerce to 'true' or 'false'
    }),

    /**
     * The `role` attribute of the tab element.
     *
     * See http://www.w3.org/TR/wai-aria/roles#tab
     *
     * @property ariaRole
     * @type String
     * @default 'tab'
     */
    ariaRole: 'tab',
    attributeBindings: ['aria-controls', 'aria-expanded', 'aria-selected', 'href', 'selected', 'tabindex'],
    classNameBindings: ['active'],
    classNames: ['ivy-tabs-tab'],

    click(event) {
      event.preventDefault();
      this.select();
    },

    href: (0, _object.computed)('tabPanel.elementId', 'tagName', function () {
      if (this.get('tagName') !== 'a') {
        return;
      }

      return '#' + this.get('tabPanel.elementId');
    }).readOnly(),

    /**
     * The index of this tab in the `ivy-tabs-tablist` component.
     *
     * @property index
     * @type Number
     */
    index: (0, _object.computed)('tabs.[]', function () {
      return this.get('tabs').indexOf(this);
    }),

    init() {
      this._super(...arguments);

      (0, _runloop.once)(this, this._registerWithTabList);
    },

    /**
     * Whether or not this tab is selected.
     *
     * @property isSelected
     * @type Boolean
     */
    isSelected: (0, _object.computed)('tabList.selectedTab', function () {
      return this.get('tabList.selectedTab') === this;
    }),

    /**
     * Object to uniquely identify this tab within the tabList.
     *
     * @property model
     * @type Object
     */
    model: null,

    /**
     * Called when the user clicks on the tab. Selects this tab.
     *
     * @method select
     */
    select() {
      const onSelect = this.get('on-select');

      if (!this.isDestroying && typeof onSelect === 'function') {
        onSelect(this.get('model'));
      }
    },

    /**
     * The `selected` attribute of the tab element. If the tab's `isSelected`
     * property is `true` this will be the literal string 'selected', otherwise
     * it will be `undefined`.
     *
     * @property selected
     * @type String
     */
    selected: (0, _object.computed)('isSelected', function () {
      if (this.get('isSelected')) {
        return 'selected';
      }
    }),

    /**
     * The `ivy-tabs-tablist` component this tab belongs to.
     *
     * @property tabList
     * @type IvyTabs.IvyTabListComponent
     * @default null
     */
    tabList: null,

    /**
     * The `ivy-tabs-tabpanel` associated with this tab.
     *
     * @property tabPanel
     * @type IvyTabs.IvyTabPanelComponent
     */
    tabPanel: (0, _object.computed)('tabPanels.@each.model', 'model', function () {
      return this.get('tabPanels').findBy('model', this.get('model'));
    }),

    /**
     * The array of all `ivy-tabs-tabpanel` instances within the `ivy-tabs`
     * component.
     *
     * @property tabPanels
     * @type Array | IvyTabs.IvyTabPanelComponent
     * @readOnly
     */
    tabPanels: (0, _computed.readOnly)('tabsContainer.tabPanels'),

    /**
     * Makes the selected tab keyboard tabbable, and prevents tabs from getting
     * focus when clicked with a mouse.
     *
     * @property tabindex
     * @type Number
     */
    tabindex: (0, _object.computed)('isSelected', function () {
      if (this.get('isSelected')) {
        return 0;
      }
    }),

    /**
     * The array of all `ivy-tabs-tab` instances within the `ivy-tabs-tablist` component.
     *
     * @property tabs
     * @type Array | IvyTabs.IvyTabComponent
     * @readOnly
     */
    tabs: (0, _computed.readOnly)('tabList.tabs'),

    /**
     * The `ivy-tabs` component.
     *
     * @property tabsContainer
     * @type IvyTabs.IvyTabsComponent
     * @readOnly
     */
    tabsContainer: (0, _computed.readOnly)('tabList.tabsContainer'),
    tagName: 'a',

    willDestroy() {
      this._super(...arguments);

      (0, _runloop.once)(this, this._unregisterWithTabList);
    }

  }).reopenClass({
    positionalParams: ['model']
  });

  _exports.default = _default;
});