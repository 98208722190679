/* globals self */
self.deprecationWorkflow = self.deprecationWorkflow || {};
self.deprecationWorkflow.config = {
    workflow: [
    { handler: 'silence', matchId: 'ember-simple-auth.initializer.setup-session-restoration' },
    { handler: 'silence', matchId: 'ember-simple-auth.events.session-service' },
    { handler: 'silence', matchId: 'ember-simple-auth.mixins.application-route-mixin' },
    { handler: 'silence', matchId: 'ember-simple-auth.mixins.authenticated-route-mixin' },

    { handler: 'silence', matchId: 'deprecated-run-loop-and-computed-dot-access' },
    // - ember-simple-auth-auth0 (run.cancel)

    { handler: 'silence', matchId: 'ember-metal.get-with-default' },
    // - ember-intercom-io

    { handler: 'silence', matchId: 'ember-global' },
    // - ember-copy brought in by ember-data-has-many-query

    { handler: 'silence', matchId: 'ember.built-in-components.legacy-arguments' },
    // - ember-cli-markdown-editor (is fixed in 0.3.0)
    // https://github.com/martinic/ember-cli-markdown-editor/blob/v0.2.0/addon/templates/components/markdown-editor.hbs#L33

    { handler: 'silence', matchId: 'this-property-fallback' },
    // ember-ace - https://github.com/dfreeman/ember-ace/pull/58
    // ivy-tabs

    // Deprecations have been cleared will throw an exception (in non-prod builds) if reintroduced
    { handler: 'throw', matchId: 'ember-test-helpers.rendering-context.jquery-element' },
    { handler: 'throw', matchId: 'ember.globals-resolver' },
    { handler: 'throw', matchId: 'ember-string.prototype-extensions' },
    { handler: 'throw', matchId: 'has-block-and-has-block-params' },
    { handler: 'throw', matchId: 'implicit-injections' },
    { handler: 'throw', matchId: 'ember-polyfills.deprecate-merge' },
    { handler: 'throw', matchId: 'routing.transition-methods' },
    { handler: 'throw', matchId: 'application-controller.router-properties' },
    { handler: 'throw', matchId: 'ember-component.is-visible' },
    { handler: 'throw', matchId: 'ember-source.deprecation-without-for' },
    { handler: 'throw', matchId: 'ember-source.deprecation-without-since' },
    { handler: 'throw', matchId: 'glimmer.private-in-element' },
    { handler: 'throw', matchId: 'manager-capabilities.modifiers-3-13' },
    { handler: 'throw', matchId: 'ember-component.send-action' },
    { handler: 'throw', matchId: 'computed-property.volatile' },
    { handler: 'throw', matchId: 'deprecate-router-events' },
    { handler: 'throw', matchId: 'ember-glimmer.link-to.positional-arguments' },
    { handler: 'throw', matchId: 'ember.built-in-components.legacy-attribute-arguments' },
    { handler: 'throw', matchId: 'ember-data:model.toJSON' },
    { handler: 'throw', matchId: 'computed-property.override' },

    // Resolved – but throws when opening Ember Inspector so silenced instead
    { handler: 'silence', matchId: 'ember.built-in-components.import' },
  ]
};
