define("ember-changeset-validations/utils/handle-multiple-validations", ["exports", "@ember/array", "rsvp", "@ember/object", "@ember/utils", "ember-changeset/utils/is-promise"], function (_exports, _array, _rsvp, _object, _utils, _isPromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = handleMultipleValidations;

  /**
   * Rejects `true` values from an array of validations. Returns `true` when there
   * are no errors, or the error object if there are errors.
   *
   * @private
   * @param  {Array} validations
   * @return {Boolean|Any}
   */
  function handleValidations() {
    let validations = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    let rejectedValidations = (0, _array.A)(validations).reject(validation => (0, _utils.typeOf)(validation) === 'boolean' && validation);
    return (0, _object.get)(rejectedValidations, 'length') === 0 || rejectedValidations;
  }
  /**
   * Handles an array of validators and returns Promise.all if any value is a
   * Promise.
   *
   * @public
   * @param  {Array} validators Array of validator functions
   * @param  {String} options.key
   * @param  {Any} options.newValue
   * @param  {Any} options.oldValue
   * @param  {Object} options.changes
   * @param  {Object} options.content
   * @return {Promise|Boolean|Any}
   */


  function handleMultipleValidations(validators, _ref) {
    let {
      key,
      newValue,
      oldValue,
      changes,
      content
    } = _ref;
    let validations = (0, _array.A)(validators.map(validator => validator(key, newValue, oldValue, changes, content)));

    if ((0, _array.A)(validations).any(_isPromise.default)) {
      return (0, _rsvp.all)(validations).then(handleValidations);
    }

    return handleValidations(validations);
  }
});