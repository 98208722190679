define("ivy-tabs/templates/components/ivy-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4mxdKRVK",
    "block": "[[[18,1,[[28,[37,1],null,[[\"tablist\",\"tabpanel\"],[[50,\"ivy-tabs-tablist\",0,null,[[\"selection\",\"tabsContainer\"],[[33,3],[30,0]]]],[50,\"ivy-tabs-tabpanel\",0,null,[[\"selection\",\"tabsContainer\"],[[33,3],[30,0]]]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"selection\"]]",
    "moduleName": "ivy-tabs/templates/components/ivy-tabs.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});