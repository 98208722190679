define("ember-svg-jar/inlined/check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.055 4.422q0 .312-.219.531l-6.719 6.719q-.219.219-.531.219t-.531-.219L1.164 7.781Q.945 7.562.945 7.25t.219-.531l1.062-1.062q.219-.219.531-.219t.531.219l2.297 2.305 5.125-5.133q.219-.219.531-.219t.531.219l1.062 1.062q.219.219.219.531z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  };
  _exports.default = _default;
});