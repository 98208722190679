define("ivy-tabs/components/ivy-tabs-tabpanel", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/runloop"], function (_exports, _component, _object, _computed, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module ivy-tabs
   */

  /**
   * @class IvyTabPanelComponent
   * @namespace IvyTabs
   * @extends Ember.Component
   */
  var _default = _component.default.extend({
    _registerWithTabsContainer() {
      this.get('tabsContainer').registerTabPanel(this);
    },

    _unregisterWithTabsContainer() {
      this.get('tabsContainer').unregisterTabPanel(this);
    },

    /**
     * Accessed as a className binding to apply the panel's `activeClass` CSS
     * class to the element when the panel's `isSelected` property is true.
     *
     * @property active
     * @type String
     * @readOnly
     */
    active: (0, _object.computed)('isSelected', function () {
      if (this.get('isSelected')) {
        return this.get('activeClass');
      }
    }),

    /**
     * The CSS class to apply to a panel's element when its `isSelected` property
     * is `true`.
     *
     * @property activeClass
     * @type String
     * @default 'active'
     */
    activeClass: 'active',

    /**
     * Tells screenreaders whether or not the panel is visible.
     *
     * See http://www.w3.org/TR/wai-aria/states_and_properties#aria-hidden
     *
     * @property aria-hidden
     * @type Boolean
     * @readOnly
     */
    'aria-hidden': (0, _object.computed)('isSelected', function () {
      return `${!this.get('isSelected')}`;
    }).readOnly(),

    /**
     * Tells screenreaders which tab labels this panel.
     *
     * See http://www.w3.org/TR/wai-aria/states_and_properties#aria-labelledby
     *
     * @property aria-labelledby
     * @type String
     * @readOnly
     */
    'aria-labelledby': (0, _computed.readOnly)('tab.elementId'),

    /**
     * See http://www.w3.org/TR/wai-aria/roles#tabpanel
     *
     * @property ariaRole
     * @type String
     * @default 'tabpanel'
     */
    ariaRole: 'tabpanel',
    attributeBindings: ['aria-hidden', 'aria-labelledby', 'tabindex'],
    classNameBindings: ['active'],
    classNames: ['ivy-tabs-tabpanel'],

    init() {
      this._super(...arguments);

      (0, _runloop.once)(this, this._registerWithTabsContainer);
    },

    /**
     * Whether or not this panel's associated tab is selected.
     *
     * @property isSelected
     * @type Boolean
     * @readOnly
     */
    isSelected: (0, _object.computed)('model', 'selection', function () {
      return this.get('model') === this.get('selection');
    }).readOnly(),

    /**
     * Object to uniquely identify this tab within the tabList.
     *
     * @property model
     * @type Object
     */
    model: null,

    /**
     * The `ivy-tabs-tab` associated with this panel.
     *
     * @property tab
     * @type IvyTabs.IvyTabComponent
     */
    tab: (0, _object.computed)('model', 'tabs.@each.model', function () {
      const tabs = this.get('tabs');

      if (tabs) {
        return tabs.findBy('model', this.get('model'));
      }
    }),

    /**
     * Makes the selected tab keyboard tabbable, and prevents tabs from getting
     * focus when clicked with a mouse.
     *
     * @property tabindex
     * @type Number
     */
    tabindex: (0, _object.computed)('isSelected', function () {
      if (this.get('isSelected')) {
        return 0;
      }
    }),

    /**
     * The array of all `ivy-tabs-tab` instances within the `ivy-tabs-tablist` component.
     *
     * @property tabs
     * @type Array | IvyTabs.IvyTabComponent
     * @readOnly
     */
    tabs: (0, _computed.readOnly)('tabsContainer.tabList.tabs'),

    /**
     * The `ivy-tabs` component.
     *
     * @property tabsContainer
     * @type IvyTabs.IvyTabsComponent
     * @default null
     */
    tabsContainer: null,

    willDestroy() {
      this._super(...arguments);

      (0, _runloop.once)(this, this._unregisterWithTabsContainer);
    }

  }).reopenClass({
    positionalParams: ['model']
  });

  _exports.default = _default;
});