define("ember-changeset/utils/validate-nested-obj", ["exports", "@ember/debug"], function (_exports, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateNestedObj;
  const {
    keys
  } = Object;

  function validateNestedObj(label, obj) {
    keys(obj).forEach(key => {
      key.split('.').forEach((_, i, allParts) => {
        if (i < allParts.length - 1) {
          let path = allParts.slice(0, i + 1).join('.');
          let msg = `Object "${label}" may not have keys that override each other.`;
          (true && !(!(path in obj)) && (0, _debug.assert)(msg, !(path in obj)));
        }
      });
    });
  }
});