define("ember-ace/templates/components/ember-ace", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WJbbm9dj",
    "block": "[[[10,\"pre\"],[14,\"data-ember-ace\",\"\"],[15,0,[36,0]],[12],[13],[1,\"\\n\\n\"],[18,1,[[28,[37,2],null,[[\"completion-tooltip\"],[[50,\"ember-ace-completion-tooltip\",0,null,[[\"suggestion\"],[[33,4]]]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"editorClass\",\"yield\",\"hash\",\"component\",\"suggestionToRender\"]]",
    "moduleName": "ember-ace/templates/components/ember-ace.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});