define("ember-svg-jar/inlined/rotate-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12 7q0 1.219-.477 2.328t-1.281 1.914-1.914 1.281T6 13q-1.344 0-2.555-.566t-2.062-1.598q-.055-.078-.051-.176t.066-.16l1.07-1.078q.078-.07.195-.07.125.016.18.094.57.742 1.398 1.148T5.999 11q.813 0 1.551-.316t1.277-.855.855-1.277.316-1.551-.316-1.551-.855-1.277-1.277-.855-1.551-.316q-.766 0-1.469.277t-1.25.793L4.35 5.15q.242.234.109.539-.133.312-.461.312h-3.5q-.203 0-.352-.148t-.148-.352v-3.5q0-.328.312-.461.305-.133.539.109l1.016 1.008q.836-.789 1.91-1.223T5.998 1q1.219 0 2.328.477t1.914 1.281 1.281 1.914T11.998 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "12",
      "height": "14",
      "viewBox": "0 0 12 14"
    }
  };
  _exports.default = _default;
});