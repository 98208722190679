define("ember-validators/index", ["exports", "@ember/debug", "@ember/utils", "ember-require-module"], function (_exports, _debug, _utils, _emberRequireModule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validate = validate;

  function validate(type) {
    let validator = (0, _emberRequireModule.default)(`ember-validators/${type}`);
    (true && !((0, _utils.isPresent)(validator)) && (0, _debug.assert)(`Validator not found of type: ${type}.`, (0, _utils.isPresent)(validator)));

    for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }

    return validator(...args);
  }
});