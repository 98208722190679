define("ember-click-outside/component", ["exports", "ember-click-outside/mixin", "@ember/component", "@ember/runloop", "ember-click-outside/utils", "@ember/object/computed"], function (_exports, _mixin, _component, _runloop, _utils, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable ember/no-component-lifecycle-hooks */

  /* eslint-disable ember/require-tagless-components */

  /* eslint-disable ember/no-classic-classes */

  /* eslint-disable ember/no-classic-components */
  var _default = _component.default.extend(_mixin.default, {
    'except-selector': (0, _computed.deprecatingAlias)('exceptSelector', {
      id: 'ember-click-outside.kebab-cased-props',
      until: '2.0.0'
    }),
    action: (0, _computed.deprecatingAlias)('onClickOutside', {
      id: 'ember-click-outside.action-prop',
      until: '2.0.0'
    }),

    clickOutside(e) {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      const exceptSelector = this.exceptSelector;

      if (exceptSelector && (0, _utils.closest)(e.target, exceptSelector)) {
        return;
      }

      let onClickOutside = this.onClickOutside;

      if (typeof onClickOutside === 'function') {
        onClickOutside(e);
      }
    },

    didInsertElement() {
      this._super(...arguments);

      this._cancelOutsideListenerSetup = (0, _runloop.next)(this, this.addClickOutsideListener);
    },

    willDestroyElement() {
      this._super(...arguments);

      (0, _runloop.cancel)(this._cancelOutsideListenerSetup);
      this.removeClickOutsideListener();
    }

  });

  _exports.default = _default;
});