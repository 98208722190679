define("ember-svg-jar/inlined/list-ul", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M3 11q0 .625-.438 1.062T1.5 12.5t-1.062-.438T0 11t.438-1.062T1.5 9.5t1.062.438T3 11zm0-4q0 .625-.438 1.062T1.5 8.5.438 8.062 0 7t.438-1.062T1.5 5.5t1.062.438T3 7zm11 3.25v1.5q0 .102-.074.176T13.75 12h-9.5q-.102 0-.176-.074T4 11.75v-1.5q0-.102.074-.176T4.25 10h9.5q.102 0 .176.074t.074.176zM3 3q0 .625-.438 1.062T1.5 4.5.438 4.062 0 3t.438-1.062T1.5 1.5t1.062.438T3 3zm11 3.25v1.5q0 .102-.074.176T13.75 8h-9.5q-.102 0-.176-.074T4 7.75v-1.5q0-.102.074-.176T4.25 6h9.5q.102 0 .176.074T14 6.25zm0-4v1.5q0 .102-.074.176T13.75 4h-9.5q-.102 0-.176-.074T4 3.75v-1.5q0-.102.074-.176T4.25 2h9.5q.102 0 .176.074T14 2.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  };
  _exports.default = _default;
});