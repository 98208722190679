define("ember-changeset/-private/err", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Err {
    constructor(value, validation) {
      this.value = value;
      this.validation = validation;
    }

  }

  _exports.default = Err;
});