define("ember-font-awesome/utils/try-match", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(object, regex) {
    return typeof object === 'string' && object.match(regex);
  }
});