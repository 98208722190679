define("ember-svg-jar/inlined/chain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.375 9.5q0-.312-.219-.531L9.531 7.344Q9.312 7.125 9 7.125q-.328 0-.563.25l.148.145q.125.122.168.168t.117.148.102.199.027.215q0 .312-.219.531T8.249 9q-.117 0-.215-.027t-.199-.102-.148-.117-.168-.168-.145-.148q-.258.242-.258.57 0 .312.219.531l1.609 1.617q.211.211.531.211.312 0 .531-.203l1.148-1.141q.219-.219.219-.523zM5.883 3.992q0-.312-.219-.531L4.055 1.844q-.219-.219-.531-.219-.305 0-.531.211L1.845 2.977q-.219.219-.219.523 0 .312.219.531L3.47 5.656q.211.211.531.211.328 0 .563-.242l-.148-.145q-.125-.122-.168-.168t-.117-.148-.102-.199-.027-.215q0-.312.219-.531T4.752 4q.117 0 .215.027t.199.102.148.117.168.168.145.148q.258-.242.258-.57zM12.875 9.5q0 .937-.664 1.586l-1.148 1.141q-.648.648-1.586.648-.945 0-1.594-.664l-1.609-1.617q-.648-.648-.648-1.586 0-.961.688-1.633l-.688-.688q-.672.688-1.625.688-.937 0-1.594-.656L.782 5.094Q.126 4.438.126 3.5T.79 1.914L1.938.773Q2.586.125 3.524.125q.945 0 1.594.664l1.609 1.617q.648.648.648 1.586 0 .961-.688 1.633l.688.688Q8.047 5.625 9 5.625q.937 0 1.594.656l1.625 1.625q.656.656.656 1.594z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "13",
      "height": "14",
      "viewBox": "0 0 13 14"
    }
  };
  _exports.default = _default;
});